const initialState = {
  value_irritabillity: 0,
  value_mania: 0,
  value_anxiety: 0,
  value_panic: 0,
  value_despondency: 0,
  value_depression: 0,
  value_text: '',
  value_emotionList: [],
  value_loggedIn: false,
  value_date: '',
}

export default initialState;