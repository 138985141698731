export const infoData = [
  {
    id: 1,
    title: "Мания",
    text: "Приподнятое настроение, эйфория, активность, планы, мечты, всё легко и просто, стремление к свободе, нарушение правил, норм, устоев.",
    name: 'mania',
  },
  {
    id: 2,
    title: "Раздражительность",
    text: "Проявление агрессии, непринятия, претензий, попытки насильственно изменить себя и людей, нарушение границ.",
    name: 'irritabillity',
  },
  {
    id: 3,
    title: "Тревожность",
    text: "Неуверенность, беспокойство, страхи, внутренние метания относительно реальных ситуаций, людей и событий.",
    name: 'anxiety',
  },
  {
    id: 4,
    title: "Паника",
    text: "Несоответствующий реальной обстановке приступ страха, неконтролируемый волевым усилием; неуверенность в себе, желание спрятаться и скрыться.",
    name: 'panic',
  },
  {
    id: 5,
    title: "Уныние",
    text: "Психологический настрой на негатив, представление будущего и прошлого в мрачной перспективе, отрицание позитивных сторон жизни.",
    name: 'despondency',
  },
  {
    id: 6,
    title: "Депрессия",
    text: "Физиологическое состояние бессилия, отсутствие инициативы, отсутствие желаний, отсутствие сил удовлетворить текущие потребности.",
    name: 'depression',
  },
];

const infoPhone = [
  {
    id: 1,
    text: "Не обязательно для заполнения. В будущем планируется сервис, который будет предупреждать близких вам людей о проблемах в вашем эмоциональном состоянии, даже если вы будете не в силах об этом рассказать:)"
  }
];

export const date = new Date().toLocaleDateString();

export const currentMonth = new Date().getMonth();

export const currentYear = new Date().getFullYear();

export const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

